import { isJpmCountry } from "@/helpers/constants";
import { CountryCode, IUser, Routes, TRequestPermissionLevels, TResponsePermissionLevels } from "@/types";

export const getRequestPermissions = (user?: IUser) =>
  (user?.userAttributes
    ?.filter(v => v.type === "admin_request_permission")
    ?.map(v => v.value) as TRequestPermissionLevels[]) || [];
export const getResponsePermissions = (user?: IUser) =>
  (user?.userAttributes
    ?.filter(v => v.type === "admin_response_permission")
    ?.map(v => v.value) as TResponsePermissionLevels[]) || [];

type PermissionObject = {
  countries: boolean;
  reqPermissions: TRequestPermissionLevels[];
  resPermissions: TResponsePermissionLevels[];
  other: boolean;
};
const initPermissionObject = (userReqPerm: TRequestPermissionLevels[], userResPerm: TResponsePermissionLevels[]) => {
  return ({ countries, reqPermissions, resPermissions, other }: PermissionObject) => {
    const isAllowedReq = userReqPerm.some(v => reqPermissions.includes(v as TRequestPermissionLevels));
    const isAllowedRes = userResPerm.some(v => resPermissions.includes(v as TResponsePermissionLevels));

    return {
      countries,
      reqPermissions: isAllowedReq,
      resPermissions: isAllowedRes,
      other,
      isAllowed: countries && (isAllowedReq || isAllowedRes) && other,
    };
  };
};

export const permissionsByPage = (
  country: CountryCode,
  hideRequestService: boolean,
  hideAccounts: boolean,
  isAlertEnabled: boolean,
  hideParticipantsCache: boolean,
  user?: IUser,
) => {
  const reqPermissionsArr = getRequestPermissions(user);
  const resPermissionsArr = getResponsePermissions(user);

  const createPermissionObject = initPermissionObject(reqPermissionsArr, resPermissionsArr);

  // ----- Dashboard -----
  const dashboardAllowed = createPermissionObject({
    countries: !(country === CountryCode.NL || country === CountryCode.FR || country === CountryCode.EPC),
    reqPermissions: ["Administration", "Transaction History", "ITSM", "Participant Management"],
    resPermissions: ["Administration", "Transaction History", "ITSM", "Participant Management"],
    other: true,
  });
  // ----- Dashboard -----

  // ----- RequestPayee -----
  const requestPayeeAllowed = createPermissionObject({
    countries: country !== CountryCode.EPC,
    reqPermissions: ["Administration", "CoP Check", "ITSM"],
    resPermissions: [],
    other: !hideRequestService,
  });
  // ----- RequestPayee -----

  // ----- Req Transaction History -----
  const reqTHAllowed = createPermissionObject({
    countries: country !== CountryCode.EPC,
    reqPermissions: ["Administration", "Transaction History", "ITSM"],
    resPermissions: [],
    other: !hideRequestService,
  });
  // ----- Req Transaction History -----

  // ----- Req MI Report -----
  const reqMIReportAllowed = createPermissionObject({
    countries: country === CountryCode.UK,
    reqPermissions: ["Administration", "MI Report Access", "ITSM"],
    resPermissions: [],
    other: !hideRequestService,
  });
  // ----- Req MI Report -----

  // ----- Req Settings -----
  const reqMIReportSettingsAllowed = createPermissionObject({
    countries: country === CountryCode.UK,
    reqPermissions: ["Administration", "MI Report Access"],
    resPermissions: [],
    other: !hideRequestService,
  });
  // ----- Req Settings -----

  // ----- Req MI Report(GLOBAL) -----
  const reqMIReportAllowedGlobal = createPermissionObject({
    countries: country === CountryCode.GLOBAL,
    reqPermissions: ["Administration", "MI Report Access", "ITSM"],
    resPermissions: [],
    other: !hideRequestService,
  });
  // ----- Req MI Report(GLOBAL) -----

   // ----- Req Settings(GLOBAL) -----
   const reqMIReportSettingsAllowedGlobal = createPermissionObject({
    countries: country === CountryCode.GLOBAL,
    reqPermissions: ["Administration", "MI Report Access"],
    resPermissions: [],
    other: !hideRequestService,
  });
  // ----- Req Settings(GLOBAL) -----

  // ----- Req Participants -----
  const reqParticipantsAllowed = createPermissionObject({
    countries: country === CountryCode.UK || country === CountryCode.BF || country === CountryCode.GLOBAL,
    reqPermissions: ["Administration", "ITSM", "Participant Management"],
    resPermissions: [],
    other: !hideRequestService,
  });
  // ----- Req Participants -----

  // ----- Res Transaction History -----
  const resTHAllowed = createPermissionObject({
    countries: !(country === CountryCode.NL || isJpmCountry(country)),
    reqPermissions: [],
    resPermissions: ["Administration", "Transaction History", "ITSM"],
    other: true,
  });
  // ----- Res Transaction History -----

  // ----- Res Accounts -----
  const resAccountsAllowed = createPermissionObject({
    countries:
      country === CountryCode.UK ||
      country === CountryCode.BF ||
      country === CountryCode.GLOBAL ||
      country === CountryCode.FR ||
      country === CountryCode.EPC,
    reqPermissions: [],
    resPermissions: ["Administration", "Account Management"],
    other: !hideAccounts,
  });
  // ----- Res Accounts -----

  // ----- Res Settings -----
  const resSettingsAllowed = createPermissionObject({
    countries: country === CountryCode.UK || country === CountryCode.GLOBAL || country === CountryCode.EPC,
    reqPermissions: [],
    resPermissions: ["Administration", "Account Management"],
    other: true,
  });
  // ----- Res Settings -----

  // ----- Res Participants -----
  const resParticipantsAllowed = createPermissionObject({
    countries: country === CountryCode.UK || country === CountryCode.BF || country === CountryCode.GLOBAL,
    reqPermissions: [],
    resPermissions: ["Administration", "ITSM", "Participant Management"],
    other: true,
  });
  // ----- Res Participants -----

  // ----- Settings User Management -----
  const settingsUserAllowed = createPermissionObject({
    countries: true,
    reqPermissions: ["Administration"],
    resPermissions: ["Administration"],
    other: true,
  });
  // ----- Settings User Management -----

  // ----- Settings Performance -----
  const settingsPerformanceAllowed = createPermissionObject({
    countries: !(country === CountryCode.BF || country === CountryCode.EPC),
    reqPermissions: ["Administration", "ITSM", "Participant Management"],
    resPermissions: ["Administration", "ITSM", "Participant Management"],
    other: true,
  });
  // ----- Settings Performance -----

  // ----- Settings Alerts -----
  const settingsAlertsAllowed = createPermissionObject({
    countries: !(
      country === CountryCode.BF ||
      country === CountryCode.FR ||
      country === CountryCode.NL ||
      country === CountryCode.EPC ||
      isJpmCountry(country)
    ),
    reqPermissions: [],
    resPermissions: ["Administration"],
    other: isAlertEnabled,
  });
  // ----- Settings Alerts -----

  // ----- Settings Participant Cache -----
  const settingsPCacheAllowed = createPermissionObject({
    countries: !(country === CountryCode.FR || country === CountryCode.NL || isJpmCountry(country)),
    reqPermissions: ["Administration", "ITSM", "Participant Management"],
    resPermissions: ["Administration", "Participant Management"],
    other: !(hideRequestService || hideParticipantsCache),
  });
  // ----- Settings Participant Cache -----

  // ----- Internal Routes -----
  const internalAllowed = createPermissionObject({
    countries: true,
    reqPermissions: ["Internal", "External"],
    resPermissions: ["Internal", "External"],
    other: true,
  });
  // ----- Internal Routes -----

  return {
    [Routes.DASHBOARD]: dashboardAllowed,

    [Routes.REQUEST_CONFIRM_SINGLE_PAYEE]: requestPayeeAllowed,
    [Routes.REQUEST_CONFIRM_SINGLE_PAYEE_TRANSACTIONS]: requestPayeeAllowed,
    [Routes.REQUEST_CONFIRM_BATCH_PAYEE]: requestPayeeAllowed,

    [Routes.REQUEST_TRANSACTION_HISTORY]: reqTHAllowed,

    [Routes.REQUEST_MI_REPORT]: reqMIReportAllowed,
    [Routes.REQUEST_GLOBAL_MI_REPORT]: reqMIReportAllowedGlobal,
    [Routes.REQUEST_SETTINGS_MI_REPORT]: reqMIReportSettingsAllowed,
    [Routes.REQUEST_GLOBAL_MI_REPORT_SETTINGS]: reqMIReportSettingsAllowedGlobal,
    [Routes.REQUEST_SETTINGS]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.REQUEST_PARTICIPANTS]: reqParticipantsAllowed,
    [Routes.REQUEST_PARTICIPANTS_MANUAL_DCR]: reqParticipantsAllowed,

    [Routes.REQUEST_CONFIRM_PAYEE]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },
    [Routes.REQUEST]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.RESPONSE_SERVICE_HISTORY]: resTHAllowed,
    [Routes.RESPONSE_SERVICE_ACCOUNTS]: resAccountsAllowed,

    [Routes.RESPONSE_SERVICE_SETTINGS_HONORIFICS]: resSettingsAllowed,
    [Routes.RESPONSE_SERVICE_SETTINGS_DESCRIPTORS]: resSettingsAllowed,
    [Routes.RESPONSE_SERVICE_SETTINGS_LEVENSHTEIN]: resSettingsAllowed,
    [Routes.RESPONSE_SERVICE_SETTINGS]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.RESPONSE_SERVICE_PARTICIPANTS]: resParticipantsAllowed,
    [Routes.RESPONSE]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.USER_MANAGEMENT]: settingsUserAllowed,
    [Routes.PERFORMANCE_METRICS]: settingsPerformanceAllowed,
    [Routes.ALERTS]: settingsAlertsAllowed,
    [Routes.PARTICIPANTS_CACHE]: settingsPCacheAllowed,
    [Routes.SETTINGS]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.ADMIN_PROFILE]: {
      countries: true,
      reqPermissions: true,
      resPermissions: true,
      other: true,
      isAllowed: true,
    },

    [Routes.APPS]: internalAllowed,
    [Routes.PROFILE]: internalAllowed,
    [Routes.USER]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },
  } as {
    [p: string]: ReturnType<typeof createPermissionObject>;
  };
};

export const findNearestAllowedRoute = (
  routesWithPermissions: ReturnType<typeof permissionsByPage>,
  subRoute?: string,
) => {
  for (const [route, permission] of Object.entries(routesWithPermissions)) {
    if (permission.isAllowed) {
      if (!subRoute) {
        return route;
      }
      if (subRoute && route.includes(subRoute)) {
        return route;
      }
    }
  }
  return "";
};
